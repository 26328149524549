import "./Landing.css"

import React from "react";

const Landing = () => {


    return (
        <div className={"landing-container"}>
            <h1 className="firstName">Whitney</h1>
            <h1 className="lastName">DeCock</h1>
        </div>
    )
}

export default Landing